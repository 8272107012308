<template>
  <div class="bg-settings-header font-blue">
    <b-container>
      <h1 class="prolog-title">Wie lernen wir in der Zukunft?</h1>
      <h2 class="prolog-claim">Gemeinsam mit euch haben wir an euren Fragen zum Lernen von (ÜBER)MORGEN gearbeitet!</h2>
      <p class="xs-margin-100">
        Ob Podcast, Hackathon oder Book a Question-Session: 
        In unserem Projekt "enorM" zur Zukunft des Lernens gab es viel Spannendes zu entdecken.
      </p>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'prolog-component',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .bg-settings-header {
    background-color: #FFFFFF;
    background-image: url('~@/assets/pattern_header.svg');
    padding-top: 10rem;
  } 

  @media screen and (max-width: 992px) {
    .bg-settings-header {
      padding-top: 7rem;
    }
  }
</style>
