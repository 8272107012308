<template>
  <footer>
    <b-container class="footer-paddings">
      <b-row>
        <b-col class="social-links" col sm="12" md="3">
          <b-link href='https://twitter.com/dipf_aktuell'><img class="mb-3 mb-lg-0" src="~@/assets/twitter_icon.png"></b-link>
          <b-link href='https://www.facebook.com/bildungsforschung.dipf/'><img class="mb-3 mb-lg-0" src="~@/assets/facebook_icon.png"></b-link>
          <b-link href='https://www.instagram.com/dipf_aktuell/'><img class="mb-3 mb-lg-0" src="~@/assets/instagram_icon.png"></b-link>
        </b-col>
        <b-col class="site-links my-3 my-md-0" col sm="12" md="9">
          <b-row>
            <b-col md="auto">
              <router-link :to="'/imprint'">Impressum</router-link><br>
              <router-link :to="'/accessibility'">Barrierefreiheit</router-link>
            </b-col>
            <b-col md="auto">
              <router-link :to="'/privacy'">Datenschutz</router-link><br>
              <router-link :to="'/simplelanguage'">Leichte Sprache</router-link>
            </b-col>
            <b-col md="auto">
              <router-link :to="'/conditions'">Teilnahmebedingungen</router-link><br>
              <router-link :to="'/signlanguage'">Gebärdensprache</router-link>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="mt-5 logo-links text-center">
        <b-col><b-link href='https://www.bmbf.de'><img src="~@/assets/logo_bmbf.png"></b-link></b-col>
        <b-col><b-link href='https://www.dipf.de/de'><img src="~@/assets/logo_dipf.png"></b-link></b-col>
        <b-col><b-link href='https://www.wissenschaftsjahr.de/2022/'><img src="~@/assets/logo_wissenschaftsjahr.png"></b-link></b-col>
        <b-col><b-link href='https://www.bildungsserver.de'><img src="~@/assets/logo_DBS.png"></b-link></b-col>
        <b-col><b-link href='https://www.idea-frankfurt.eu/'><img src="~@/assets/logo_idea.png"></b-link></b-col>
        <b-col><b-link href='https://www.leibniz-bildung.de'><img src="~@/assets/logo_leibnitz.png"></b-link></b-col>
      </b-row>
    </b-container>
  </footer>
</template>

<script>
//   import Vue from 'vue'

  export default {
    name: 'footer-component',
    data () {
      return {
      }
    }
  }
</script>
<style lang="scss" scoped>

  a {
    color: #121282!important;
    font-size: 1.25rem;
  }

  .social-links {
    a {
      margin-right: 16px;
    }
  }

  .site-links {
    a {
      margin-bottom: 13px;
      display: inline-flex;
      font-weight: 400;
      font-size: 1.3rem;
    }
  }

  .logo-links {
    img {
      padding: 10px 0 10px 0;
    }    
  }

</style>
