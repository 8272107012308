<template>
  <div class="bg-settings font-white">
    <b-container>
      <h2>
        Sammle deine coolen "New School Tools" für das Lernen in der Zukunft!
      </h2>
      <p>
        Wie machst du das? Spiele dich durch unser Labyrinth und entdecke die Ideen zum Lernen von morgen,
        die Schüler*innen in unserem Projekt entwickelt haben.
      </p>
      <p>
        Welches coole Tool gefällt dir am besten? Finde es heraus! Vorher musst du aber ein paar Challenges bestehen ... warte nicht länger - los geht's!
      </p>
      <p>
        <a href="/game/" target="_blank">
          <img :src="require('@/assets/game_teaser_image.png')" alt="Klicke hier um zum SPiel zu gelangen"/>
        </a>
      </p>
      <p>
        <video playsinline controls loop :poster="require('@/assets/video_trailer.png')">
          <source src="@/assets/enorm_trailer_crf30.mp4" type='video/mp4'>
          <source src="@/assets/enorm_trailer_crf30.webm" type='video/webm'>
        </video>
      </p>
      <h2 style="text-transform: none;">enorM - UM WAS ES UNS GEHT:</h2>
      <p>
        Während der Corona-Zeit hat sich das Lernen gewaltig verändert. Schule, das war auf einmal das heimische Wohnzimmer mit dem Esstisch,
        auf dem sich schnell die ausgedruckten Arbeitsblätter stapelten. Virtuelle Lernplattformen wurden mal besser, mal schlechter zum Laufen gebracht.
      </p>
      <p>
        Digitales Lernen wird in Zukunft noch viel stärker eine Rolle spielen, das ist klar. Und vielleicht finden sich auch Lehr-Roboter in Klassenräumen wieder.
        Aber der Punkt ist, dass viele Dinge an den Schulen, die heute noch normal erscheinen, in Zukunft anders sein werden.
      </p>
      <p>
        Um die Zukunft nicht den Gremien und Konferenzen von Politik, Wissenschaft und Verbänden zu überlassen, haben wir die gefragt und um Ideen zur
        Zukunft des Lernens gebeten, die sie am meisten betreffen: die Schüler*innen.
        <br><br>
        Also: Macht mit!
      </p>
      <h2>Welche Fragen stellst Du dir, wenn Du über das Lernen und über die Schule der Zukunft nachdenkst?</h2>
      <p>
        Das Projekt „enorM - Lernen von (ÜBER)MORGEN“ hat insbesondere über diese Webseite Fragen von Schüler*innen zum Lernen in der Zukunft gesammelt.
        Gemeinsam mit Schüler*innen aus ganz Deutschland haben Forschende des <b-link href="https://www.dipf.de/de" target="_blank">DIPF | Leibniz-Institut für Bildungsforschung</b-link>
        und Bildungsinformation diese Fragen weiter bearbeitet. Dazu haben wir verschiedene Workshops und Veranstaltungen vorbereitet: So wurden beispielsweis eine Podcast-Reihe
        erstellt und ein Serious Game entwickelt.
      </p>
      <p>
        Das Projekt „enorM - Lernen von (ÜBER)MORGEN“ wurde im Rahmen des Wissenschaftsjahres 2022 - Nachgefragt! durchgeführt. Organisiert wurde die
        Aktion vom <b-link href="https://www.bmbf.de" target="_blank">Bundesministerium für Bildung und Forschung (BMBF)</b-link>.
      </p>
      <p>
        Wir vom Projekt „enorM“ arbeiten am <b-link href="https://www.dipf.de/de" target="_blank">
        DIPF | Leibniz-Institut für Bildungsforschung und Bildungsinformation</b-link>.
        Am Institut befassen sich viele Wissenschaftler*innen mit unterschiedlichen Fragen rund um Bildung. Sie untersuchen, wie Kinder lesen,
        schreiben und rechnen lernen und wie man diejenigen, die dabei etwas mehr Hilfe benötigen, unterstützen kann.
      </p>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'about-component',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  video, img {
    width: 100%;
    // margin-bottom: 6rem;
  }
</style>
