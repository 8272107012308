<template>
  <div>
    <app-prolog></app-prolog>
    <!-- <app-send></app-send> -->
    <app-trailer></app-trailer>
    <app-about></app-about>
  </div>
</template>

<script>
// @ is an alias to /src
import Prolog from '@/components/Prolog.vue'
// import SendForm from '@/components/SendForm.vue'
import Trailer from '@/components/Trailer.vue'
import About from '@/components/About.vue'

export default {
  name: 'home-view',
  components: {
    'app-prolog': Prolog,
    // 'app-send': SendForm,
    'app-trailer' : Trailer,
    'app-about' : About

  }
}
</script>
