<template>
  <div class="bg-settings font-white">
    <b-container>
      <div class="blogEntries" v-for="item in blogEntries" :key="item.content_id">
            <h2>{{ item.title }}</h2>
            <p style="white-space: pre-wrap;" v-html="item.text"></p>
      </div>
      <img :src="require('@/assets/Session_1_Kuenstliche_Intelligenz_und_digitales_Lernen.jpg')" alt=""/>
      <img :src="require('@/assets/Session_2_Emotionale_und_mentale_Gesundheit.jpg')" alt=""/>
      <img :src="require('@/assets/Session_3_Selbsbestimmtes_Lernen_Inhalte.jpg')" alt=""/>
      <img :src="require('@/assets/Session_4_Individuelles_Lernen_mit_Technologien.jpg')" alt=""/>
      <br><br><br><br><br>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'trailer-component',
  data() {
      return {
        blogEntries: []
      }
  },

  methods: {
    fetchBlogEntries() {
      return new Promise((resolve) => {
        fetch('/data/blogentries.json', {
          method: 'GET',
          headers: {"Content-Type": "application/json"},
        }).then((resp) => resp.json())
          .then((response) => {
            resolve(response)
          })
      })
    }
  },
  mounted () {
      // setup
      this.fetchBlogEntries().then(data => {
        this.blogEntries = data.blog_entries
      })
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .bg-settings {
    background-image: url('~@/assets/pattern_02.svg');
  }
  img {
    margin: 20px 0;
    width: 100%;
  }
</style>
