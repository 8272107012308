import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/collection',
    name: 'Collection',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Collection.vue')
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Contact.vue')
    }
  },
  {
    path: '/imprint',
    name: 'Imprint',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Imprint.vue')
    }
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Privacy.vue')
    }
  },
  {
    path: '/simplelanguage',
    name: 'Simplelanguage',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Simplelanguage.vue')
    }
  },
  {
    path: '/signlanguage',
    name: 'Signlanguage',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Signlanguage.vue')
    }
  },
  {
    path: '/conditions',
    name: 'Conditions',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Conditions.vue')
    }
  },
  {
    path: '/accessibility',
    name: 'Accessibility',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Accessibility.vue')
    }
  }
]

const router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
})

export default router
