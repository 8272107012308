<!--
  - Copyright (C) 2022. Archimedes Exhibitions GmbH,
  - Saarbrücker Str. 24, Berlin, Germany
  -
  - This file contains proprietary source code and confidential
  - information. Its contents may not be disclosed or distributed to
  - third parties unless prior specific permission by Archimedes
  - Exhibitions GmbH, Berlin, Germany is obtained in writing. This applies
  - to copies made in any form and using any medium. It applies to
  - partial as well as complete copies.
  -->

<template>
  <div id="app">
    <b-alert 
      class="position-fixed fixed-bottom m-0 rounded-0 text-center py-5"
      v-model="cookieBannerVisible"
      variant="primary" 
      fade>
      Durch die Nutzung unserer Website stimmen Sie unseren <b-link to="/privacy" target="_self">Datenschutz-Richtlinien</b-link> zu. 
      <b-button class="mx-4" @click="closeBanner()" variant="outline-primary" size="sm">Einverstanden</b-button>
    </b-alert>
    <app-nav></app-nav>
    <router-view></router-view>
    <app-footer></app-footer>
    <!-- <img id="test-overlay" src='~@/assets/screens/start_large_and_medium_992_bis_768_px.png'/> -->
  </div>
</template>

<script>
  import Navbar from '@/components/Navbar'
  import Footer from '@/components/Footer'

  export default {
    components: {
      'app-nav': Navbar,
      'app-footer': Footer
    },
    data () {
      return {
        cookieBannerVisible: true
      }
    },
    methods: {
      closeBanner() {
        this.$cookies.set("policy-confirmed", true);
        this.cookieBannerVisible = false

      }
    },
    created() {
      this.cookieBannerVisible = !this.$cookies.get('policy-confirmed') 
    }
  }
</script>

<style lang="scss">
  #test-overlay {
    position: absolute;
    opacity: 0.4;
    top: 12px;
    left: -42px;
    pointer-events: none;
  }
</style>
