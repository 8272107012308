<template>
  <b-navbar toggleable="lg" type="light" class="navbar navbar-light">
    <b-container>
      <b-navbar-brand to="/">
        <b-navbar-brand tag="h1" class="mb-0"><b-img :src="require('@/assets/logo_enorm.png')"></b-img></b-navbar-brand>
      </b-navbar-brand>
      <b-navbar-toggle target="nav-text-collapse"></b-navbar-toggle>
      <b-collapse id="nav-text-collapse" is-nav>
        <b-navbar-nav class=nav-customs>
          <b-nav-item to="/contact">Kontakt</b-nav-item>
          <b-nav-item v-if="this.$route.name !== 'Home'" to="/">Startseite</b-nav-item>
          <b-nav-item v-if="this.$route.name === 'Home'" to="/collection">Eure Fragen</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-container>
  </b-navbar>
</template>

<script>
//   import Vue from 'vue'

  export default {
    name: 'navbar-component',
    data () {
      return {}
    },
    methods: {}
  }
</script>

<style lang="scss" scoped>
  .navbar {
    border-bottom: 2px solid #121282;
    padding: 0px;
  }
  .nav-customs {
    margin-left: auto;
  }
  .nav-link{
    font-size: 1.5em;
    color: #121282!important;
    line-height: 32px;
    text-align: right;
    font-weight: normal;
        margin-left: 40px;
  }
</style>